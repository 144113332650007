import '../node_modules/foundation-sites/dist/js/foundation';
import '../node_modules/foundation-sites/dist/js/plugins/foundation.core';
import '../node_modules/foundation-sites/dist/js/plugins/foundation.tooltip';

(function() {
    var cx = '009751647513547887654:leos5jsrzcw';
    var gcse = document.createElement('script');
    gcse.type = 'text/javascript';
    gcse.async = true;
    gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(gcse, s);
})();

$(window).on('load',function(){
    var q=location.pathname.replace("/index.html","").trim();
    if (q) {
        $('#gsc-i-id1').val( decodeURIComponent(location.pathname).replace(/^\//,"") );
        $('.gsc-search-button').trigger('click');
    }
});
$(function() {
    var source_instance = new Foundation.Tooltip($('#source_instance'), {});
    $(document).on('show.zf.tooltip', function() {
        setTimeout(function(){
            $('#' + $('#source_instance').attr('aria-describedby')).css("max-width", '30rem');
        }, 10);
        
    });
    
});
